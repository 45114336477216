@if(selectedYear() == 0) {
<div class="row g-0">
  @for(year of years; track year){

  <div class="col-md-3 text-center mt-4" (click)="selectYear(year)">
    <div class="btn btn-outline-white">
      <h2 class="mb-0">{{ year }}</h2>
    </div>
  </div>

  }
</div>
} @else {

<div class="d-flex justify-content-center negative-margin">
  <p class="me-4" (click)="back()">
    <i class="fa fa-chevron-left me-2"></i>Back
  </p>
  <h2>{{ selectedYear() }}</h2>
</div>
<app-photo-gallery [year]="selectedYear()"></app-photo-gallery>
}

<div
  class="row g-0"
  [photoGalleryGroup]="{
    arrowEl: true,
    indexIndicatorSep: ' - ',

    allowPanToNext: true
  }"
>
  @for(image of images; track image){ @if(!!image.Key){
  <div class="col">
    <div
      class="awards-image-item-image text-center mt-4"
      [photoGallery]="getImageUrl(image.Key)"
    >
      <img
        [src]="getImageUrl(image.Key)"
        [width]="300"
        [height]="200"
        srcset
        @fadeIn
      />
    </div>
  </div>
  } }
</div>
